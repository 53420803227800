// fallback default values from the /defaults Config Api endpoint.
const FALLBACK_DEFAULTS: DefaultSettings = {
  table: {
    stream: {
      hot_data_max_age_minutes: 3,
      hot_data_max_active_partitions: 3,
      hot_data_max_rows_per_partition: 12288000,
      hot_data_max_minutes_per_partition: 1,
      hot_data_max_open_seconds: 60,
      hot_data_max_idle_seconds: 30,
      cold_data_max_age_days: 3650,
      cold_data_max_active_partitions: 50,
      cold_data_max_rows_per_partition: 12288000,
      cold_data_max_minutes_per_partition: 60,
      cold_data_max_open_seconds: 300,
      cold_data_max_idle_seconds: 60,
      message_queue_max_rows: 0,
    },
    age: { max_age_days: 0 },
    reaper: { max_age_days: 1 },
    merge: {
      enabled: true,
      partition_duration_minutes: 60,
      input_aggregation: 20000000000,
      max_candidates: 20,
      max_rows: 10000000,
      max_partitions_per_candidate: 100,
      min_age_mins: 1,
      max_age_mins: 10080,
      memory_coefficient: 0,
      pools: {
        small: '',
        medium: '',
        large: '',
      },
    },
    autoingest: {
      enabled: false,
      pattern: '',
      max_rows_per_partition: 12288000,
      destination: '/tmp/scratch',
      max_minutes_per_partition: 60,
      max_active_partitions: 50,
      input_aggregation: 1073741824,
      dry_run: false,
      source: '',
    },
    sort_keys: [],
    shard_key: null,
  },
  transform: {
    is_default: false,
    output_columns: [],
    format_details: {
      delimiter: ',',
      skip_head: 0,
      quote: '"',
      comment: '#',
      skip_comments: false,
      escape: '"',
      windows_ending: false,
      buffer_size: 4096,
      flattening: {
        active: false,
        map_flattening_strategy: null,
        slice_flattening_strategy: null,
      },
    },
    compression: 'none',
  },
};

export default FALLBACK_DEFAULTS;
